<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="text-right">#</th>
                <th>{{ $t('team') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(division, index) in divisions" :key="index">
                <td class="text-right">{{ index + 1 }}</td>
                <td>
                  <router-link :to="{ name: 'DivisionHome', params: { lang:lang, console: console, divisionSlug: division.slug } }">
                    <img :src="division.image" :alt="division.name" class="img-icon mr-2">
                    {{ division.name }}
                  </router-link>
                </td>
                <td>
                  {{ division.time }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data () {
    return {
      divisions: [],
      timer: null
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
  created() {
    this.$store.dispatch('SET_MENU_PRIMARY_TOURNAMENT', 'home')
    this.fetchData()
  },
  methods: {
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.tournamentSlug
      const path = `${lang}/console/${console}/tournament/${slug}/transfers`
      this.$axios.get(path).then((response) => {
        this.divisions = response.data.data
        this.timer = setInterval(() => {
          this.updateTime()
        }, 1000);
      })
    },
    updateTime() {
      this.divisions = this.divisions.map(division => {
        const now = moment()
        if (division.start_transfer && division.end_transfer) {
          const startDate = moment(division.start_transfer)
          const endDate = moment(division.end_transfer)
          if (now.isBetween(startDate, endDate)) {
            division.time = this.$t('transferClose', { time: endDate.locale(this.lang).fromNow(true) })
          } else if (now.isBefore(startDate)) {
            division.time = this.$t('transferOpen', { time: startDate.locale(this.lang).fromNow(true) })
          }
        }
        return division
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
